import React from "react";
import Helmet from "react-helmet";
import ContactUs from "@tightrope/footerlinks/contact-us";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js";
import styles from 'src/styles/shared/legal-pages.module.scss';

export default function Contact() {
    return (
        <HomepageLayout>
            <Helmet data={JSONData}>
                <title>Contact Us</title>
            </Helmet>
            <div className={styles.legalPage}>
                <header>
                    <img src="/img/mainlogo.png" alt="Main Logo" />
                </header>
                <div className={styles.container}>
                    <ContactUs data={JSONData} hideFooter={true} />
                </div>
            </div>
            <RawFooter />
        </HomepageLayout>
    );
}
